/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useState } from 'react';
import {
	Grid,
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
} from "react-bootstrap";
import PagesService from '../../services/pagesService.js';
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import avatar from "assets/img/faces/face-3.jpg";
import Dropzone from 'react-dropzone';
import upload from 'superagent';
import { any } from "prop-types";
import axios from 'axios';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader'
import Select from "react-select";
import JoditEditor from "jodit-react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Redirect } from 'react-router-dom';
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";

const emptySection = {
	serialNumber: 0,
	name: '',
	contentType: '',
	title: '',
	videoLink: '',
	videoTitle: '',
	videoExcerpt: '',
	images: [{
		title: '',
		url: '',
		excerpt: ''
	}],
	description: ''
}

class PagesForm extends Component {
	notify = (message) => { toast.success(message) };

	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			redirectUrl: '',
			page: {
				pageName: '',
				pageType: '',
				sections: [{
					serialNumber: Number,
					name: String,
					contentType: String,
					title: String,
					videoLink: String,
					videoTitle: String,
					videoExcerpt: String,
					column1: String,
					column2: String,
					column3: String,
					images: [{
						title: String,
						url: String,
						excerpt: String
					}],
					description: String,
					addDivider: Boolean
				}],
				seoTitle: String,
				seoDescription: String,
				slug: String

			},
			imagepath: '',
			fields: [],
			imageFields: [{ title: '', url: '', excerpt: '' }],
			allPages: []

		};
		this.PagesService = new PagesService();
		this.routeParam = props.match.params.id;
		this.imagepath = '';
		this.contentType = '';
		this.selectedValue = ''
		this.label = ''
		this.selectedParent = [];
		this.parentIds = [];
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
		// this.setState({
		//   [name]: value
		// });
	  }

	componentWillMount() {
		this.getPages();
		if (this.routeParam) {
			this.fetchPage();
		} else {
			this.setState({
				page: {
					pageName: '',
					pageType: '',
					sections: [],
					seoTitle: String,
					seoDescription: String,

				},
				imagepath: '',
				fields: [],
				imageFields: [{ title: '', url: '', excerpt: '' }],
				allPages: []
			});
		}

	}

	onDropButtonImage(file) {
		console.log('-->>this is file', file)
		upload.post(process.env.REACT_APP_BACKEND_URL + 'api/v1/portfolio/upload')
			.attach('theseNamesMustMatch', file[0])
			.end((err, res) => {
				console.log('==>>urll->>>', res.body.body.url)
				this.setState({
					...this.state,
					page: {
						...this.state.page,
						buttonImage: res.body.body.url
					}
				})
				// this.state.pageContent.images.image1.url = res.body.body.url
			})
	}
	onDrop(file, sectionId, innerId) {
		console.log('-->>this is file', file)
		upload.post(process.env.REACT_APP_BACKEND_URL + 'api/v1/portfolio/upload')
			.attach('theseNamesMustMatch', file[0])
			.end((err, res) => {
				console.log('==>>urll->>>', res.body.body.url)
				// this.state.imageFields[innerId].url = res.body.body.url
				var sections = this.state.page.sections;
				sections[sectionId].images[innerId] = {
					...this.state.page.sections[sectionId].images[innerId],
					url: res.body.body.url
				}
				this.setState({
					...this.state,
					page: {
						...this.state.page,
						sections: sections
					}
				})


			})
	}
	onDropVideo(file, sectionId) {
		console.log('-->>this is file', file)
		upload.post(process.env.REACT_APP_BACKEND_URL + 'api/v1/portfolio/upload')
			.attach('theseNamesMustMatch', file[0])
			.end((err, res) => {

				var sections = this.state.page.sections;
				sections[sectionId] = {
					...this.state.page.sections[sectionId],
					videoLink: res.body.body.url
				}
				this.setState({
					...this.state,
					page: {
						...this.state.page,
						sections: sections
					}
				})
			})
	}

	handleChange = (e) => {
		console.log('->>e>>>>', e.target.value);
		this.setState({
			page: {
				...this.state.page,
				[e.target.name]: e.target.value

			}
		})
		console.log(this.state.pageContent)
	}

	handleChangePageContent = (e) => {
		console.log(e);
		this.setState({
			...this.state,
			pageContent: {
				...this.state.pageContent,
				[e.target.name]: e.target.value
			}
		})
		console.log(this.state.pageContent)
	}

	handleContentTypeSelectionChange = (e) => {
		console.log(e);
		this.setState({
			page: {
				...this.state.page,
				sections: e.value
			}
		})
		this.contentType = e.value;
		console.log('updated--->>>', this.state.page.contentType)
		this.showHideFields();
	}

	handlePageTypeSelectionChange = (e) => {
		console.log(e);
		this.setState({
			page: {
				...this.state.page,
				pageType: e.value
			}
		})
		console.log('updated--->>>', this.state.page.pageType)
		this.showHideFields();
	}

	async getPages() {
		var pages = await this.PagesService.getPages();
		console.log('====>>pages--->>', pages)
		this.setState({
			...this.state,
			allPages: pages
		})
	}
	async handleButtonClick(e) {

		e.preventDefault();
		this.setState({
			page: {
				...this.state.page,
				slug: this.state.page.pageName.toLowerCase()
					.replace(/ /g, '-')
					.replace(/[^\w-]+/g, '')

			}
		}, async() => {
			console.log('new state---->>>>>',this.state)
			if (this.routeParam) {
				var buttonClickResponse = await this.PagesService.updatePage(this.routeParam, this.state.page);
				console.log('resp==>>>', buttonClickResponse)
				if (buttonClickResponse.response.code == 200) {
					var message = 'Page updated successfully'
					this.notify(message);
					
	
				}
			} else {
				var buttonClickResponse = await this.PagesService.addPage(this.state.page);
	
				if (buttonClickResponse.status.code == 200) {
					var message = 'Page added successfully'
					this.notify(message);
					this.setState({
						...this.state,
						redirect: true,
						redirectUrl: '/admin/pages/edit/'+buttonClickResponse.body.page._id
					});
	
				}
				console.log(buttonClickResponse);
	
			}
		})
		
		
	}

	async fetchPage() {
		var page = await this.PagesService.getPage(this.routeParam);
		this.setState({
			page: {
				pageName: page.pageName,
				sections: page.sections,
				seoTitle: page.seoTitle,
				seoDescription: page.seoDescription,
				slug: page.slug ? page.slug : page.pageName.toLowerCase()
					.replace(/ /g, '-')
					.replace(/[^\w-]+/g, ''),
				pageType: page.pageType
			}
		});


		this.contentType = page.contentType

		this.showHideFields();

		console.log('pageType--->>>>', this.state)
	}
	getLabel(valueString) {
		console.log('THE LABEL', valueString);
		var result = valueString.toString().replace(/([A-Z])/g, " $1")
		var label = result.charAt(0).toUpperCase() + result.slice(1);
		return valueString;
	}

	showHideFields() {
		this.state.fields = [];
		if (this.contentType === 'text' || this.contentType === 'conversionResponse' || this.contentType === 'conversionForm') {
			this.state.fields.push('title', 'description')
		} else if (this.contentType === 'video') {
			this.state.fields.push('title', 'videoLink')
		} else if (this.contentType === 'imageGallery') {
			this.state.fields.push('title', 'description', 'images')
		} else if (this.contentType === 'videoImage') {
			this.state.fields.push('videoLink', 'images')
		}
		console.log(this.state.fields)
	}

	handleImageTitleChange = (e, id) => {
		console.log(this.state.fields)
		this.state.imageFields[id].title = e.target.value
		console.log(this.state.imageFields[id])

	}

	handleImageExcerptChange = (e, id) => {
		this.state.imageFields[id].excerpt = e.target.value

	}
	handleSectionContentTypeChange = (id, value) => {
		var oldSections = this.state.page.sections;
		var toBeUpdateOldSection = oldSections[id]
		toBeUpdateOldSection.contentType = value;
		oldSections[id] = toBeUpdateOldSection;
		console.log(this.state.page.sections)
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: oldSections
			}
		})


		console.log('id and value==>>>', id + ' --- ' + value)
	}

	handlePageTypeChange = (value) => {

		this.setState({
			...this.state,
			page: {
				...this.state.page,
				pageType: value
			}
		})
	}

	handleChangeEditorCol1 = (e) => {
		console.log(e);
		this.setState({
			project: {
				...this.state.project,
				description: e
			}
		})
		console.log(this.state.project)
	}

	addFields(sectionsId) {
		console.log(sectionsId)
		var newSections = this.state.page.sections;
		newSections[sectionsId].images.push({ title: '', url: '', excerpt: '' });
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: newSections
			}
		});
		console.log(this.state)

	}
	addSections() {
		console.log('EMPTY', emptySection);

		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: [
					...this.state.page.sections,
					{
						addDivider: false,
						serialNumber: 0,
						name: '',
						contentType: '',
						title: '',
						videoLink: '',
						videoTitle: '',
						videoExcerpt: '',
						column1: '',
						column2: '',
						column3: '',
						images: [{
							title: '',
							url: '',
							excerpt: ''
						}],
						description: ''
					}
				]
			}
		})
	}
	setSectionContentLevelOne(e, sectionId) {
		console.log('-->checkbox--',e.target.value);
		var sections = this.state.page.sections;
		sections[sectionId] = {
			...this.state.page.sections[sectionId],
			[e.target.name]: e.target.value
		}
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: sections
			}
		})
		console.log(this.state.page.sections)
	}
	addCheckBoxValue(e, sectionId) {
		console.log('-->checkbox--',e.target.value);
		var checkboxValue = false
		if(this.state.page.sections[sectionId].addDivider == true) {
			checkboxValue = false;
		} else {
			checkboxValue = true;
		}
		var sections = this.state.page.sections;
		sections[sectionId] = {
			...this.state.page.sections[sectionId],
			[e.target.name]: checkboxValue
		}
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: sections
			}
		})
		console.log(this.state.page.sections)
	}
	// addCheckBoxValue = changeEvent => {
	// 	console.log(changeEvent.target)
	// }
	handleDescriptionChange(e, sectionId) {
		console.log(e);
		var sections = this.state.page.sections;
		sections[sectionId] = {
			...this.state.page.sections[sectionId],
			description: e
		}
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: sections
			}
		})
		console.log(this.state.page.sections)
	}
	handleCol1(e, sectionId) {
		var sections = this.state.page.sections;
		sections[sectionId] = {
			...this.state.page.sections[sectionId],
			column1: e
		}
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: sections
			}
		})
		console.log(this.state.page.sections)
	}
	handleCol2(e, sectionId) {
		var sections = this.state.page.sections;
		sections[sectionId] = {
			...this.state.page.sections[sectionId],
			column2: e
		}
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: sections
			}
		})
		console.log(this.state.page.sections)
	}
	handleCol3(e, sectionId) {
		var sections = this.state.page.sections;
		sections[sectionId] = {
			...this.state.page.sections[sectionId],
			column3: e
		}
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: sections
			}
		})
		console.log(this.state.page.sections)
	}

	setSectionContentLevelTwo(e, sectionId, innerId) {
		console.log(e.target.value);
		var sections = this.state.page.sections;
		sections[sectionId].images[innerId] = {
			...this.state.page.sections[sectionId].images[innerId],
			[e.target.name]: e.target.value
		}
		this.setState({
			...this.state,
			page: {
				...this.state.page,
				sections: sections
			}
		})
		console.log(this.state.page.sections)
	}
	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirectUrl} />
		} const uploadOptions = {
			server: 'http://3.106.14.188:4000',
			signingUrlQueryParams: { uploadType: 'avatar' },
		}
		const s3Url = 'https://new-idsite-2019.s3-ap-southeast-2.amazonaws.com';
		var titleField;
		var descriptionField;
		var videoField;
		var videoTitle;
		var videoExcerpt;
		var imagesBlock;

		return (
			<div className="content">
				<ToastContainer />
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								title="Add/Edit Pages"
								content={
									<form>

										<FormInputs
											ncols={["col-md-12"]}
											properties={[
												{
													label: "Page Name",
													type: "text",
													bsClass: "form-control",
													placeholder: "Page Name",
													name: 'pageName',
													required: 'required',
													defaultValue: this.state.page ? this.state.page.pageName : '',
													onChange: (e) => {
														this.setState({
															...this.state,
															page: {
																...this.state.page,
																pageName: e.target.value,
																sections: [
																	...this.state.page.sections
																]

															}
														})
														console.log(this.state.page)
													}
												}
											]}
										/>


										<Col md={12}>
											<p className="category" >SELECT PAGE TYPE</p>
											<Select
												className="react-select primary"
												classNamePrefix="react-select"
												name='pageType'
												value={{ value: this.state.page ? this.state.page.pageType : '', label: this.state.page ? this.state.page.pageType : '' }}

												onChange={(value) => this.handlePageTypeChange(value.value)}

												options={[
													{ value: "page", label: "Page", selected: true },
													{ value: "project", label: "Project" },
												]}
												placeholder="Select Page Type"
											/>

										</Col>

										{this.state.page.sections.map((section, sectionId) => {
											return (
												<div key={`${sectionId}`}>
													<h6>Section: {`${sectionId}`}</h6>
													<Col md={12}>
														<p className="category" >SELECT CONTENT TYPE</p>
														<Select
															className="react-select primary"
															classNamePrefix="react-select"
															name='contentType'
															value={{ value: this.state.page.sections[sectionId].contentType.toString(), label: this.getLabel(this.state.page.sections[sectionId].contentType.toString()) }}

															onChange={(value) => this.handleSectionContentTypeChange(sectionId, value.value)}

															options={[
																{ value: "text", label: "Text", selected: true },
																{ value: "video", label: "Video" },
																{ value: "imageGallery", label: "Image Gallery" },
																{ value: "videoImage", label: "Video and Image" },
																{ value: "conversionForm", label: "Conversion Form" },
																{ value: "conversionResponse", label: "Conversion Response" },
																{ value: "twoCols", label: "Two Columns" },
																{ value: "threeCols", label: "Three Columns" },

															]}
															placeholder="Select Content Type"
														/>

													</Col>
													<FormInputs
														ncols={["col-md-12"]}
														properties={[
															{
																label: "Section Name",
																type: "text",
																bsClass: "form-control",
																placeholder: "Section Name",
																name: 'name',
																required: 'required',
																defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].name : '',
																onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
															}
														]}
													/>
													{this.state.page.sections[sectionId].contentType === 'text' || this.state.page.sections[sectionId].contentType === 'conversionForm' || this.state.page.sections[sectionId].contentType === 'conversionResponse' ?
														<div>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Title",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Title",
																		name: 'title',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].title : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<FormGroup controlId="formControlsTextarea">
																<ControlLabel>Description</ControlLabel>
																<CKEditor
																	editor={ClassicEditor}
																	data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].description : ''}
																	onInit={editor => {
																		// You can store the "editor" and use when it is needed.
																		console.log('Editor is ready to use!', editor);
																	}}
																	onChange={(event, editor) => this.handleDescriptionChange(editor.getData(), sectionId)}

																/>
															</FormGroup>
														</div> : ``}
													{this.state.page.sections[sectionId].contentType === 'twoCols' ?
														<div>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Title",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Title",
																		name: 'title',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].title : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<FormGroup controlId="formControlsTextarea">
																<ControlLabel>Description</ControlLabel>
																<CKEditor
																	editor={ClassicEditor}
																	data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].description : ''}
																	onInit={editor => {
																		// You can store the "editor" and use when it is needed.
																		console.log('Editor is ready to use!', editor);
																	}}
																	onChange={(event, editor) => this.handleDescriptionChange(editor.getData(), sectionId)}

																/>
															</FormGroup>
															{/* <ControlLabel>Column 1</ControlLabel>
															<JoditEditor
																value={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].column1 : ''}
																tabIndex={1} // tabIndex of textarea
																onChange={(e) => this.handleCol1(e, sectionId)}
																name="column1"
															/>
															<ControlLabel>Column 2</ControlLabel>
															<JoditEditor
																value={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].column2 : ''}
																tabIndex={1} // tabIndex of textarea
																onChange={(e) => this.handleCol2(e, sectionId)}
																name="column2"
															/> */}
															<ControlLabel>Column 1</ControlLabel>
															<CKEditor
																editor={ClassicEditor}
																data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].column1 : ''}
																onInit={editor => {
																	// You can store the "editor" and use when it is needed.
																	console.log('Editor is ready to use!', editor);
																}}
																onChange={(event, editor) => this.handleCol1(editor.getData(), sectionId)}

															/>
															<ControlLabel>Column 2</ControlLabel>
															<CKEditor
																editor={ClassicEditor}
																data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].column2 : ''}
																onInit={editor => {
																	// You can store the "editor" and use when it is needed.
																	console.log('Editor is ready to use!', editor);
																}}
																onChange={(event, editor) => this.handleCol2(editor.getData(), sectionId)}

															/>
														</div> : ``}
													{this.state.page.sections[sectionId].contentType === 'threeCols' ?
														<div>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Title",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Title",
																		name: 'title',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].title : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<FormGroup controlId="formControlsTextarea">
																<ControlLabel>Description</ControlLabel>
																{/* <FormControl
																	rows="5"
																	componentClass="textarea"
																	bsClass="form-control"
																	placeholder="Description of the project"
																	name='description'
																	onChange={(e) => this.setSectionContentLevelOne(e, sectionId)}
																	defaultValue={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].description : ''}
																/> */}
																<CKEditor
																	editor={ClassicEditor}
																	data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].description : ''}
																	onInit={editor => {
																		// You can store the "editor" and use when it is needed.
																		console.log('Editor is ready to use!', editor);
																	}}
																	onChange={(event, editor) => this.handleDescriptionChange(editor.getData(), sectionId)}

																/>
															</FormGroup>

															<ControlLabel>Column 1</ControlLabel>
															<CKEditor
																editor={ClassicEditor}
																data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].column1 : ''}
																onInit={editor => {
																	// You can store the "editor" and use when it is needed.
																	console.log('Editor is ready to use!', editor);
																}}
																onChange={(event, editor) => this.handleCol1(editor.getData(), sectionId)}

															/>
															<ControlLabel>Column 2</ControlLabel>
															<CKEditor
																editor={ClassicEditor}
																data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].column2 : ''}
																onInit={editor => {
																	// You can store the "editor" and use when it is needed.
																	console.log('Editor is ready to use!', editor);
																}}
																onChange={(event, editor) => this.handleCol2(editor.getData(), sectionId)}

															/>
															<ControlLabel>Column 2</ControlLabel>
															<CKEditor
																editor={ClassicEditor}
																data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].column3 : ''}
																onInit={editor => {
																	// You can store the "editor" and use when it is needed.
																	console.log('Editor is ready to use!', editor);
																}}
																onChange={(event, editor) => this.handleCol3(editor.getData(), sectionId)}

															/>
														</div> : ``
													}
													{this.state.page.sections[sectionId].contentType === 'imageGallery' ?
														<div>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Title",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Title",
																		name: 'title',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].title : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<FormGroup controlId="formControlsTextarea">
																<ControlLabel>Description</ControlLabel>
																<CKEditor
																	editor={ClassicEditor}
																	data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].description : ''}
																	onInit={editor => {
																		// You can store the "editor" and use when it is needed.
																		console.log('Editor is ready to use!', editor);
																	}}
																	onChange={(event, editor) => this.handleDescriptionChange(editor.getData(), sectionId)}

																/>
															</FormGroup>
															{this.state.page.sections[sectionId].images.map((field, id) => {
																return (
																	<div key={`${field}-${id}`}>
																		<p className="category">Images</p>
																		<label htmlFor="">Image {id % 2 == 0 ? '- Landscape Image' : '- Portrait Image'}</label>
																		<div>
																			<Dropzone onDrop={(e) => this.onDrop(e, sectionId, id)} multiple={false}>
																				{({ getRootProps, getInputProps }) => (
																					<div id="dropzone" className="dropzone" className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

																				)}
																			</Dropzone>
																			{this.state.page.sections[sectionId].images[id].url !== '' ? <img src={this.state.page.sections[sectionId].images[id].url} style={{ 'max-width': 25 + '%' }} /> : ''}
																		</div>
																		<FormInputs
																			ncols={["col-md-12"]}
																			properties={[
																				{
																					label: "Image Title",
																					type: "text",
																					bsClass: "form-control",
																					placeholder: "Title",
																					name: 'title',
																					required: 'required',
																					defaultValue: this.state.page.sections[sectionId].images[id] ? this.state.page.sections[sectionId].images[id].title : '',
																					onChange: (e) => this.setSectionContentLevelTwo(e, sectionId, id)
																				}
																			]}
																		/>
																		<FormInputs
																			ncols={["col-md-12"]}
																			properties={[
																				{
																					label: "Image Excerpt",
																					type: "text",
																					bsClass: "form-control",
																					placeholder: "Excerpt",
																					name: 'excerpt',
																					required: 'required',
																					defaultValue: this.state.page.sections[sectionId].images[id] ? this.state.page.sections[sectionId].images[id].excerpt : '',
																					onChange: (e) => this.setSectionContentLevelTwo(e, sectionId, id)
																				}
																			]}
																		/>
																		<Button bsStyle="info" type="button" onClick={() => this.addFields(sectionId)}>Add More Images</Button>
																	</div>)
															})}</div> : ``}
													{this.state.page.sections[sectionId].contentType === 'video' ?

														<div>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Title",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Title",
																		name: 'title',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].title : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Video",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "http://youtube.com",
																		name: 'videoLink',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].videoLink : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<div>
																<Dropzone onDrop={(e) => this.onDropVideo(e, sectionId)} multiple={false}>
																	{({ getRootProps, getInputProps }) => (
																		<div className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

																	)}
																</Dropzone>
																{/* {this.state.page.sections[sectionId].videoLink !== '' ? <img src={this.state.page.sections[sectionId].videoLink} style={{ width: 200 + 'px', height: 200 + 'px' }} /> : ''} */}
																{this.state.page.sections[sectionId].videoLink !== '' ? <video style={{ 'max-width': 25 + '%' }} >
																	<source src={this.state.page.sections[sectionId].videoLink} type="video/mp4" />
																	<source src={this.state.page.sections[sectionId].videoLink} type="video/ogg" />
																	Your browser does not support the video tag.
																	</video> : ''}
															</div>

															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Video Title",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Video",
																		name: 'videoTitle',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].videoTitle : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Video Excerpt",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "This is the Video",
																		name: 'videoExcerpt',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].videoExcerpt : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<ControlLabel>Description</ControlLabel>
															<CKEditor
																editor={ClassicEditor}
																data={this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].description : ''}
																onInit={editor => {
																	// You can store the "editor" and use when it is needed.
																	console.log('Editor is ready to use!', editor);
																}}
																onChange={(event, editor) => this.handleDescriptionChange(editor.getData(), sectionId)}

															/>



														</div>

														: ``}

													{this.state.page.sections[sectionId].contentType === 'videoImage' ?
														<div>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Video",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "http://youtube.com",
																		name: 'videoLink',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].videoLink : '',
																		onChange: this.handleChangePageContent
																	}
																]}
															/>
															<div>
																<Dropzone onDrop={(e) => this.onDropVideo(e, sectionId)} multiple={false}>
																	{({ getRootProps, getInputProps }) => (
																		<div className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

																	)}
																</Dropzone>
																{/* {this.state.page.sections[sectionId].videoLink !== '' ? <img src={this.state.page.sections[sectionId].videoLink} style={{ width: 200 + 'px', height: 200 + 'px' }} /> : ''} */}
																{this.state.page.sections[sectionId].videoLink !== '' ? <video style={{ 'max-width': 25 + '%' }} >
																	<source src={this.state.page.sections[sectionId].videoLink} type="video/mp4" />
																	<source src={this.state.page.sections[sectionId].videoLink} type="video/ogg" />
																	Your browser does not support the video tag.
																	</video> : ''}
															</div>

															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Video Title",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "Video",
																		name: 'videoTitle',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].videoTitle : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>
															<FormInputs
																ncols={["col-md-12"]}
																properties={[
																	{
																		label: "Video Excerpt",
																		type: "text",
																		bsClass: "form-control",
																		placeholder: "This is the Video",
																		name: 'videoExcerpt',
																		required: 'required',
																		defaultValue: this.state.page.sections[sectionId] ? this.state.page.sections[sectionId].videoExcerpt : '',
																		onChange: (e) => this.setSectionContentLevelOne(e, sectionId)
																	}
																]}
															/>




															{this.state.page.sections[sectionId].images.map((field, id) => {
																return (


																	<div key={`${field}-${id}`}>
																		<p className="category">Images</p>
																		<label htmlFor="">Image {id % 2 == 0 ? '- Landscape Image' : '- Portrait Image'}</label>
																		<div>
																			<Dropzone onDrop={(e) => this.onDrop(e, sectionId, id)} multiple={false}>
																				{({ getRootProps, getInputProps }) => (
																					<div id="dropzone" className="dropzone" className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

																				)}
																			</Dropzone>
																			{this.state.page.sections[sectionId].images[id].url !== '' ? <img src={this.state.page.sections[sectionId].images[id].url} style={{ 'max-width': 25 + '%' }} /> : ''}
																		</div>
																		<FormInputs
																			ncols={["col-md-12"]}
																			properties={[
																				{
																					label: "Image Title",
																					type: "text",
																					bsClass: "form-control",
																					placeholder: "Title",
																					name: 'title',
																					required: 'required',
																					defaultValue: this.state.page.sections[sectionId].images[id] ? this.state.page.sections[sectionId].images[id].title : '',
																					onChange: (e) => this.setSectionContentLevelTwo(e, id)
																				}
																			]}
																		/>
																		<FormInputs
																			ncols={["col-md-12"]}
																			properties={[
																				{
																					label: "Image Excerpt",
																					type: "text",
																					bsClass: "form-control",
																					placeholder: "Excerpt",
																					name: 'excerpt',
																					required: 'required',
																					defaultValue: this.state.page.sections[sectionId].images[id] ? this.state.page.sections[sectionId].images[id].excerpt : '',
																					onChange: (e) => this.setSectionContentLevelTwo(e, sectionId, id)
																				}
																			]}
																		/>
																		<Button bsStyle="info" type="button" onClick={() => this.addFields(sectionId)}>Add More Images</Button>
																	</div>)
															})}</div>
														:

														``
														
													}
													<label>
													Add Divider After this section? 
													<input
														name="addDivider"
														type="checkbox"
														checked={this.state.page.sections[sectionId].addDivider === 'on' || this.state.page.sections[sectionId].addDivider === true ? true : false}
														onChange={(e) => this.addCheckBoxValue(e, sectionId)} />
													</label>
													{/* <label>Add Divider?</label>{sectionId}
													<Checkbox name="addDivider" number={sectionId}
													isChecked={this.state.page.sections[sectionId].addDivider === 'on' || this.state.page.sections[sectionId].addDivider === 'true' ? true : false}
													onChange= {(e) => this.addCheckBoxValue(e, sectionId)}
													/> */}
												</div>
											)
										})}



										<FormInputs
											ncols={["col-md-12"]}
											properties={[
												{
													label: "SEO Title",
													type: "text",
													bsClass: "form-control",
													placeholder: "SEO Title",
													name: 'seoTitle',
													required: 'required',
													defaultValue: this.state.page ? this.state.page.seoTitle : '',
													onChange: (e) => this.handleChange(e)
												}
											]}
										/>
										<FormGroup controlId="formControlsTextarea">
											<ControlLabel>SEO Description</ControlLabel>
											<FormControl
												rows="5"
												componentClass="textarea"
												bsClass="form-control"
												placeholder="SEO Description"
												name='seoDescription'
												onChange={(e) => this.handleChange(e)}
												value={this.state.page ? this.state.page.seoDescription : ''}
											/>
										</FormGroup>
										
										<Button bsStyle="info" type="button" onClick={() => this.addSections()}>Add Sections</Button>
										<Button bsStyle="info" pullRight fill onClick={(e) => this.handleButtonClick(e)}>
											{this.routeParam ? 'Update Page' : 'Add Page'}
										</Button>

										<div className="clearfix" />
									</form>
								}
							/>
						</Col>

					</Row>
				</Grid>
			</div>

		);
	}
}

export default PagesForm;

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import ProjectsService from '../../services/ProjectsService.js';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import Card from "components/Card/Card.jsx";
import { thArray, tdArray } from "variables/Variables.jsx";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
class Projects extends Component {
  notify = (message) => { toast.success(message); };
  constructor(props) {
    super(props);
    this.state = { projects: [] };
    this.projectsService = new ProjectsService();
  }
  componentDidMount() {
    this.getProjects();
  }

  async getProjects() {
    var projects = await this.projectsService.getProjects();
    console.log('====>>projects--->>', projects)
    if (projects.projects) {
      this.setState({ projects: projects.projects })
    }
    else {
      this.setState({ project: {} })
    }
  }

  deleteProject(id) {
    confirmAlert({
      title: 'Delete Project',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.confirmDelete(id)
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });

  }

  async confirmDelete(id) {
    var deleteResponse = await this.projectsService.deleteProject(id);
    if (deleteResponse.code == 200) {
      console.log('here------', deleteResponse);
      var message = deleteResponse.message;
      this.getProjects();
      this.notify(message);
    } else {
      this.notify('There was an error deleting the project');
    }
  }
  render() {
    const edit = <Tooltip id="edit_tooltip">Edit Task</Tooltip>;
    const remove = <Tooltip id="remove_tooltip">Remove</Tooltip>;
    return (
      <div className="content">
        <ToastContainer />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Users"
                category="Administrators"
                buttonText='Add Projects'
                buttonLink='/admin/projects/add'
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Excerpt</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.projects.map((listValue, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{listValue.name}</td>
                            <td>{listValue.excerpt}</td>
                            <td className="td-actions">
                              <Link to={"/admin/projects/edit/" + listValue._id}><OverlayTrigger placement="top" overlay={edit} >
                                <Button bsStyle="info" id={listValue._id} simple type="button" bsSize="xs">
                                  <i className="fa fa-edit" />
                                </Button>
                              </OverlayTrigger></Link>

                              <OverlayTrigger placement="top" overlay={remove}>
                                <Button bsStyle="danger" simple type="button" bsSize="xs" onClick={() => this.deleteProject(listValue._id)}>
                                  <i className="fa fa-times" />
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Projects;

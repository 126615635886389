/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import JourneySectionsService from '../../services/JourneySectionsService.js';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import Card from "components/Card/Card.jsx";
import { thArray, tdArray } from "variables/Variables.jsx";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactTable from "react-table";

class JourneySections extends Component {
  notify = (message) => { toast.success(message); };
  constructor(props) {
    super(props);
    this.state = { sections: [] };
    this.journeySectionsService = new JourneySectionsService();
  }
  componentDidMount() {
    this.getSections();
  }
  getLabel(valueString) {
		console.log('THE LABEL', valueString);
		var result = valueString.toString().replace(/([A-Z])/g, " $1")
		var label = result.charAt(0).toUpperCase() + result.slice(1);
		return label;
	}
  async getSections() {
    var sections = await this.journeySectionsService.getSections();
    console.log('====>>sections--->>', sections)
    this.setState({
      sections: sections,
      sectionData: sections.map((prop, key) => {
        return {
          id: key + 1,
          name: prop.name,
          contentType: this.getLabel(prop.contentType),
          buttonText: prop.buttonText,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a like kind of action */}
              <Link to={"/admin/sections/edit/" + prop._id}>
                <Button bsStyle="info" id={prop._id} simple type="button" bsSize="xs">
                  <i className="fa fa-edit" />
                </Button>
              </Link>
              {/* use this button to add a edit kind of action */}

              <Button bsStyle="danger" simple type="button" bsSize="xs" onClick={() => this.deleteSection(prop._id)}>
                <i className="fa fa-times" />
              </Button>

              {/* use this button to remove the data row */}

            </div>
          )
        };
      })

    })
  }

  deleteSection(id) {
    confirmAlert({
      title: 'Delete Section',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.confirmDelete(id)
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });

  }

  async confirmDelete(id) {
    var deleteResponse = await this.journeySectionsService.deleteSection(id);
    console.log('delete response---->>', deleteResponse)
    if (deleteResponse.code == 200) {
      console.log('here------', deleteResponse);
      var message = deleteResponse.message;
      this.getSections();
      this.notify(message);
    } else {
      this.notify('There was an error deleting the Section');
    }
  }
  render() {
    const edit = <Tooltip id="edit_tooltip">Edit Task</Tooltip>;
    const remove = <Tooltip id="remove_tooltip">Remove</Tooltip>;
    return (
      <div className="content">
        <ToastContainer />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Journey Sections"
                category="Administrators"
                buttonText='Add Section'
                buttonLink='/admin/sections/add'
                ctTableFullWidth
                ctTableResponsive
                content={
                  <ReactTable
                    data={this.state.sectionData}
                    filterable
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name"
                      },
                      {
                        Header: "Content Type",
                        accessor: "contentType"
                      },
                      {
                        Header: "Button Text",
                        accessor: "buttonText"
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={100}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />


                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default JourneySections;

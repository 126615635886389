/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
	Grid,
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl
} from "react-bootstrap";
import SiteSettingsService from '../../services/SiteSettingsService';
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import avatar from "assets/img/faces/face-3.jpg";
import Dropzone from 'react-dropzone';
import upload from 'superagent';
import { any } from "prop-types";
import axios from 'axios';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import JoditEditor from "jodit-react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class SiteSettingsForm extends Component {
	notify = (message) => { toast.success(message) };

	constructor(props) {
		super(props);
		this.state = {
			// settings: {
			// 	businessEmail: '',
			// 	businessPhone: '',
			// 	businessAddress: '',
			// 	seoTitle: '',
			// 	seoDescription: ''
			// },
			settings: null,
			update: false,
			id: null
		};
		this.siteSettingsService = new SiteSettingsService();
		// this.routeParam = props.match.params.id;
		// console.log('param==>>', props.match.params)
		// this.imagepath = '';

	}

	componentDidMount() {
		this.fetchSettings();
	}

	handleChange = (e) => {
		console.log(e);
		this.setState({
			...this.state,
			settings: {
				...this.state.settings,
				[e.target.name]: e.target.value
			}
		})
		console.log(this.state.settings)
	}

	handleDescriptionChange = (e) => {
		console.log(e);
		this.setState({
			...this.state,
			settings: {
				...this.state.settings,
				description: e
			}
		})
		console.log(this.state.settings)
	}

	async handleButtonClick(e) {
		e.preventDefault();
		console.log(this.state.settings);
		if (this.state.update) {
			var buttonClickResponse = await this.siteSettingsService.updateSettings(this.state.id, this.state.settings);
			console.log('resp==>>>', buttonClickResponse)
			if (buttonClickResponse.response.code == 200) {
				var message = 'Settings Saved successfully'
				this.notify(message);
			}
			console.log(buttonClickResponse);
		} else {
			var buttonClickResponse = await this.siteSettingsService.addSettings(this.state.settings);
			if (buttonClickResponse.status.code == 200) {
				var message = 'Settings added successfully'
				this.notify(message);
			}
			// console.log(buttonClickResponse);
		}
	}

	async fetchSettings() {
		var settings = await this.siteSettingsService.getSettings()
		if(settings.length > 0) {
			this.setState({
				...this.state,
				settings: settings[0],
				update: true,
				id: settings[0]._id
			})
		}
		console.log('from state-->', this.state);
		// this.setState({
		// 	project: {
		// 		name: project.name,
		// 		link: project.link,
		// 		description: project.description,
		// 		excerpt: project.excerpt,
		// 		image: project.image,
		// 		video: project.video
		// 	}
		// });

		// console.log('state-->>', this.state.settings.description);
	}

	render() {
		const uploadOptions = {
			server: 'http://3.106.14.188:4000',
			signingUrlQueryParams: { uploadType: 'avatar' },
		}
		const s3Url = 'https://new-idsite-2019.s3-ap-southeast-2.amazonaws.com'
		return (
			<div className="content">
				<ToastContainer />
				{<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								title="Edit Settings"
								content={
									<form>
										<FormInputs
											ncols={["col-md-12", "col-md-12", "col-md-12", "col-md-12"]}
											properties={[
												{
													label: "Business Email",
													type: "text",
													bsClass: "form-control",
													placeholder: "Email",
													name: 'businessEmail',
													required: 'required',
													defaultValue: this.state.settings ? this.state.settings.businessEmail : '',
													onChange: this.handleChange
												},
												{
													label: "Business Phone",
													type: "text",
													bsClass: "form-control",
													placeholder: "Business Phone",
													defaultValue: this.state.settings ? this.state.settings.businessPhone : '',
													required: 'required',
													name: 'businessPhone',
													onChange: this.handleChange
												},
												{
													label: "Business Address",
													type: "text",
													bsClass: "form-control",
													placeholder: "https://abc.com.au",
													defaultValue: this.state.settings ? this.state.settings.businessAddress : '',
													required: 'required',
													name: 'businessAddress',
													onChange: this.handleChange
												},
												{
													label: "SEO Title",
													type: "text",
													bsClass: "form-control",
													placeholder: "https://youtube.com",
													defaultValue: this.state.settings ? this.state.settings.seoTitle : '',
													required: 'required',
													name: 'seoTitle',
													onChange: this.handleChange
												}
											]}
										/>

										<FormGroup controlId="formControlsTextarea">
											<ControlLabel>SEO Description</ControlLabel>
											<FormControl
												rows="5"
												componentClass="textarea"
												bsClass="form-control"
												placeholder="Description of the webiste"
												name='seoDescription'
												onChange={this.handleChange}
												value={this.state.settings ? this.state.settings.seoDescription: ''}
											/>
										</FormGroup>

										{/* <JoditEditor
											value={this.state.settings ? this.state.settings.description : ''}
											tabIndex={1} // tabIndex of textarea
											onChange={this.handleChangeEditor}
											name="description"
										/> */}
										{/* <label htmlFor="">Description</label> */}
										{/* <CKEditor
											editor={ClassicEditor}
											data={this.state.settings ? this.state.settings.description : ''}
											onInit={editor => {
												// You can store the "editor" and use when it is needed.
												console.log('Editor is ready to use!', editor);
											}}
											onChange={(event, editor) => this.handleDescriptionChange(editor.getData())}

										/> */}


										{/* <FormInputs
											ncols={["col-md-12"]}
											properties={[
												{
													label: "Video Link",
													type: "text",
													bsClass: "form-control",
													placeholder: "https://youtube.com",
													defaultValue: this.state.settings ? this.state.settings.video : '',
													required: 'required',
													name: 'video',
													onChange: this.handleChange
												}
											]}
										/>
										<div>
											<Dropzone onDrop={(e) => this.onDropVideo(e)} multiple={false}>
												{({ getRootProps, getInputProps }) => (
													<div className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

												)}
											</Dropzone>
											{this.state.settings.video !== '' ? <video style={{ 'max-width': 25 + '%' }} >
												<source src={this.state.settings.video} type="video/mp4" />
												<source src={this.state.settings.video} type="video/ogg" />
												Your browser does not support the video tag.
																	</video> : ''} */}
											{/* {this.state.settings.video !== '' ? <img src={this.state.settings.video} style={{ 'max-width': 25 + '%' }} /> : ''} */}
										{/* </div>
										<label htmlFor="">Image</label>
										<div>
											<Dropzone onDrop={(e) => this.onDrop(e)} multiple={false}>
												{({ getRootProps, getInputProps }) => (
													<div className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

												)}
											</Dropzone>
											{this.state.settings.name !== '' ? <img src={this.state.settings.image} style={{ 'max-width': 25 + '%' }} /> : ''}
										</div>
*/
										<Button bsStyle="info" pullRight fill onClick={(e) => this.handleButtonClick(e)}>
											Save Settings
										</Button> }

										<div className="clearfix" />
									</form>
								}
							/>
						</Col>

					</Row>
				</Grid>
				}
			</div>

		);
	}
}

export default SiteSettingsForm;

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import UserProfile from "views/UserProfile.jsx";
import TableList from "views/TableList.jsx";
import Users from "views/users/Users.jsx";
import UserForm from "views/users/UserForm.jsx";
import Typography from "views/Typography.jsx";
import Icons from "views/Icons.jsx";
import Maps from "views/Maps.jsx";
import Notifications from "views/Notifications.jsx";
import Upgrade from "views/Upgrade.jsx";
import Projects from "views/projects/Projects";
import JourneySections from "views/journey/JourneySections";
import PagesList from "views/pages/PagesList";
import JourneySectionsForm from "views/journey/JourneySectionsForm";
import PagesForm from "views/pages/PagesForm";
import ProjectForm from "views/projects/ProjectForm";
import SiteSettingsForm from "views/siteSettings/SiteSettingsForm";
import LoginPage from "views/login/LoginPage";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "My Profile",
    icon: "pe-7s-user",
    component: LoginPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/user",
    name: "My Profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/listUsers",
    name: "Users List",
    icon: "pe-7s-user",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/listProjects",
    name: "Projects List",
    icon: "pe-7s-edit",
    component: Projects,
    layout: "/admin"
  },
  {
    path: "/listSections",
    name: "Sections List",
    icon: "pe-7s-news-paper",
    component: JourneySections,
    layout: "/admin"
  },
  {
    path: "/listPages",
    name: "Pages List",
    icon: "pe-7s-note2",
    component: PagesList,
    layout: "/admin"
  },
  {
    path: "/siteSettings",
    name: "Settings",
    icon: "pe-7s-tools",
    component: SiteSettingsForm,
    layout: "/admin"
  },
  {
    path: "/pages/edit/:id",
    name: "Edit Page",
    icon: "pe-7s-edit",
    component: PagesForm,
    layout: "/admin",
    invisible: true
  },{
    path: "/pages/add",
    name: "Add Pages",
    icon: "pe-7s-edit",
    component: PagesForm,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/sections/edit/:id",
    name: "Edit Section",
    icon: "pe-7s-edit",
    component: JourneySectionsForm,
    layout: "/admin",
    invisible: true
  },{
    path: "/sections/add",
    name: "Add Section",
    icon: "pe-7s-edit",
    component: JourneySectionsForm,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/edit/:id",
    name: "Edit User",
    icon: "pe-7s-edit",
    component: UserForm,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/add/",
    name: "Add User",
    icon: "pe-7s-edit",
    component: UserForm,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/projects/add/",
    name: "Add User",
    icon: "pe-7s-edit",
    component: ProjectForm,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/projects/edit/:id",
    name: "Edit Project",
    icon: "pe-7s-edit",
    component: ProjectForm,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/table",
    name: "Table List",
    icon: "pe-7s-note2",
    component: TableList,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/typography",
    name: "Typography",
    icon: "pe-7s-news-paper",
    component: Typography,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "pe-7s-science",
    component: Icons,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "pe-7s-map-marker",
    component: Maps,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: Notifications,
    layout: "/admin",
    invisible: true
  },
  {
    upgrade: true,
    path: "/upgrade",
    name: "Upgrade to PRO",
    icon: "pe-7s-rocket",
    component: Upgrade,
    layout: "/admin",
    invisible: true
  }
];

export default dashboardRoutes;

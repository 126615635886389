import  axios  from 'axios';
import Interceptor from './Interceptor';
class PagesService {
	constructor() {
		var URL = '';
	}
	
	async getPages() {
		var page  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/pages/')
		return page.data.body.pages;
	}

	async getPage(id) {
		var page  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/pages/' + id)
		console.log('==>from service', page)
		return page.data.body.page;
	}

	async addPage(data) {
		console.log('===>from service',data);
		var page  = await axios.post(process.env.REACT_APP_BACKEND_URL+'api/v1/pages/', data)
		console.log(page.data)
		return page.data;
	}
	
	async updatePage(id, data) {
		var page  = await axios.put(process.env.REACT_APP_BACKEND_URL+'api/v1/pages/' + id, data)
		return page.data.body;
	}

	async deletePage(id, data) {
		var page  = await axios.delete(process.env.REACT_APP_BACKEND_URL+'api/v1/pages/' + id)
		return page.data.body.response;
	}
}
export default PagesService;
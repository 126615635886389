import  axios  from 'axios';
import Interceptor from './Interceptor';
class UserService {
	constructor() {
		var URL = '';
	}
	
	async getUsers() {
		var user  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/users/')
		return user.data.body.users;
	}

	async login(data) {
		var user  = await axios.post(process.env.REACT_APP_BACKEND_URL+'api/v1/users/login', data)
		console.log('from service---',user)
		return user.data;
	}

	async getUser(id) {
		var user  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/users/' + id)
		return user.data.body.user[0];
	}

	async addUser(data) {
		var user  = await axios.post(process.env.REACT_APP_BACKEND_URL+'api/v1/users/', data)
		return user.data;
	}
	
	async updateUser(id, data) {
		var user  = await axios.put(process.env.REACT_APP_BACKEND_URL+'api/v1/users/' + id, data)
		return user.data.body;
	}

	async deleteUser(id, data) {
		var user  = await axios.delete(process.env.REACT_APP_BACKEND_URL+'api/v1/users/' + id)
		return user.data.body.response;
	}
}
export default UserService;
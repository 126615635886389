/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
	Grid,
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl
} from "react-bootstrap";
import ProjectsService from '../../services/ProjectsService.js';
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import avatar from "assets/img/faces/face-3.jpg";
import Dropzone from 'react-dropzone';
import upload from 'superagent';
import { any } from "prop-types";
import axios from 'axios';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import JoditEditor from "jodit-react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class ProjectForm extends Component {
	notify = (message) => { toast.success(message) };

	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			project: {
				name: '',
				link: '',
				description: '',
				excerpt: '',
				image: '',
				video: ''
			},
			imagepath: ''
		};
		this.projectService = new ProjectsService();
		this.routeParam = props.match.params.id;
		console.log('param==>>', props.match.params)
		this.imagepath = '';

	}

	componentDidMount() {
		if (this.routeParam) {
			this.fetchProject();
		} else {
			this.setState({
				project: {
					name: '',
					link: '',
					description: '',
					excerpt: '',
					image: '',
					video: ''
				}
			});
		}
	}

	onDrop(file) {
		console.log('-->>this is file', file)
		upload.post(process.env.REACT_APP_BACKEND_URL+'api/v1/portfolio/upload')
			.attach('theseNamesMustMatch', file[0])
			.end((err, res) => {
				console.log('==>>urll->>>', res.body.body.url)
				this.setState({
					project: {
						...this.state.project,
						image: res.body.body.url
					}
				})
			})
	}

	onDropVideo(file) {
		console.log('-->>this is file', file)
		upload.post(process.env.REACT_APP_BACKEND_URL+'api/v1/portfolio/upload')
			.attach('theseNamesMustMatch', file[0])
			.end((err, res) => {
				console.log('==>>urll->>>', res.body.body.url)
				this.setState({
					project: {
						...this.state.project,
						video: res.body.body.url
					}
				})
			})
	}

	handleChange = (e) => {
		console.log(e);
		this.setState({
			project: {
				...this.state.project,
				[e.target.name]: e.target.value
			}
		})
		console.log(this.state.project)
	}

	handleChangeEditor = (e) => {
		console.log(e);
		this.setState({
			project: {
				...this.state.project,
				description: e
			}
		})
		console.log(this.state.project)
	}

	handleDescriptionChange = (e) => {
		console.log(e);
		this.setState({
			project: {
				...this.state.project,
				description: e
			}
		})
		console.log(this.state.project)
	}

	uploadFile(acceptedFiles) {
		console.log(acceptedFiles);
		this.setState({ imagepath: acceptedFiles[0].path })
		this.setState({
			project: {
				...this.state.project,
				image: acceptedFiles[0]
			}
		})
		axios.post(`http://3.106.14.188:5000/api/v1/portfolio/upload`, this.state.project, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(response => {
			// handle your response;
		}).catch(error => {
			// handle your error
		});
	}

	handleFinishedUpload = info => {
		console.log('File uploaded with filename', info.filename)
		console.log('Access it on s3 at', info.fileUrl)
	}

	uploadError = error => {
		console.log('error--->>>', error)
	}

	clearFiles(e) {
		console.log(this)
		this.setState({ imagepath: '' })
		this.state.project.image = null
	}

	async handleButtonClick(e) {
		e.preventDefault();
		console.log(this.state.project);
		if (this.routeParam) {
			var buttonClickResponse = await this.projectService.updateProject(this.routeParam, this.state.project);
			console.log('resp==>>>', buttonClickResponse)
			if (buttonClickResponse.response.code == 200) {
				var message = 'Project updated successfully'
				this.notify(message);
			}
			console.log(buttonClickResponse);
		} else {
			var buttonClickResponse = await this.projectService.addProject(this.state.project);
			if (buttonClickResponse.status.code == 200) {
				var message = 'Project added successfully'
				this.notify(message);
			}
			// console.log(buttonClickResponse);
		}
	}

	async fetchProject() {
		console.log('==>oarammm', this.routeParam);
		var project = await this.projectService.getProject(this.routeParam);
		this.setState({
			project: {
				name: project.name,
				link: project.link,
				description: project.description,
				excerpt: project.excerpt,
				image: project.image,
				video: project.video
			}
		});

		console.log('state-->>', this.state.project.description);
	}

	onImageDrop(acceptedFiles) {

	}

	render() {
		const uploadOptions = {
			server: 'http://3.106.14.188:4000',
			signingUrlQueryParams: { uploadType: 'avatar' },
		}
		const s3Url = 'https://new-idsite-2019.s3-ap-southeast-2.amazonaws.com'
		return (
			<div className="content">
				<ToastContainer />
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								title="Edit Project"
								content={
									<form>
										<FormInputs
											ncols={["col-md-12", "col-md-12", "col-md-12"]}
											properties={[
												{
													label: "Name",
													type: "text",
													bsClass: "form-control",
													placeholder: "Name",
													name: 'name',
													required: 'required',
													defaultValue: this.state.project ? this.state.project.name : '',
													onChange: this.handleChange
												},
												{
													label: "Excerpt",
													type: "text",
													bsClass: "form-control",
													placeholder: "Excerpt",
													defaultValue: this.state.project ? this.state.project.excerpt : '',
													required: 'required',
													name: 'excerpt',
													onChange: this.handleChange
												},
												{
													label: "Project Link",
													type: "text",
													bsClass: "form-control",
													placeholder: "https://abc.com.au",
													defaultValue: this.state.project ? this.state.project.link : '',
													required: 'required',
													name: 'link',
													onChange: this.handleChange
												},
												{
													label: "Video Link",
													type: "text",
													bsClass: "form-control",
													placeholder: "https://youtube.com",
													defaultValue: this.state.project ? this.state.project.video : '',
													required: 'required',
													name: 'video',
													onChange: this.handleChange
												}
											]}
										/>

										{/* <FormGroup controlId="formControlsTextarea">
											<ControlLabel>Description</ControlLabel>
											<FormControl
												rows="5"
												componentClass="textarea"
												bsClass="form-control"
												placeholder="Description of the project"
												name='description'
												onChange={this.handleChange}
												value={this.state.project ? this.state.project.description: ''}
											/>
										</FormGroup> */}

										{/* <JoditEditor
											value={this.state.project ? this.state.project.description : ''}
											tabIndex={1} // tabIndex of textarea
											onChange={this.handleChangeEditor}
											name="description"
										/> */}
										<label htmlFor="">Description</label>
										<CKEditor
											editor={ClassicEditor}
											data={this.state.project ? this.state.project.description : ''}
											onInit={editor => {
												// You can store the "editor" and use when it is needed.
												console.log('Editor is ready to use!', editor);
											}}
											onChange={(event, editor) => this.handleDescriptionChange(editor.getData())}

										/>


										<FormInputs
											ncols={["col-md-12"]}
											properties={[
												{
													label: "Video Link",
													type: "text",
													bsClass: "form-control",
													placeholder: "https://youtube.com",
													defaultValue: this.state.project ? this.state.project.video : '',
													required: 'required',
													name: 'video',
													onChange: this.handleChange
												}
											]}
										/>
										<div>
											<Dropzone onDrop={(e) => this.onDropVideo(e)} multiple={false}>
												{({ getRootProps, getInputProps }) => (
													<div className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

												)}
											</Dropzone>
											{this.state.project.video !== '' ? 	<video style={{ 'max-width': 25 + '%' }} >
  																		<source src={this.state.project.video} type="video/mp4"/>
																		<source src={this.state.project.video} type="video/ogg"/>
																		Your browser does not support the video tag.
																	</video> : ''}
											{/* {this.state.project.video !== '' ? <img src={this.state.project.video} style={{ 'max-width': 25 + '%' }} /> : ''} */}
										</div>
										<label htmlFor="">Image</label>
										<div>
											<Dropzone onDrop={(e) => this.onDrop(e)} multiple={false}>
												{({ getRootProps, getInputProps }) => (
													<div className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

												)}
											</Dropzone>
											{this.state.project.name !== '' ? <img src={this.state.project.image} style={{ 'max-width': 25 + '%' }} /> : ''}
										</div>

										<Button bsStyle="info" pullRight fill onClick={(e) => this.handleButtonClick(e)}>
											{this.routeParam ? 'Update Project' : 'Add Project'}
										</Button>

										<div className="clearfix" />
									</form>
								}
							/>
						</Col>

					</Row>
				</Grid>
			</div>

		);
	}
}

export default ProjectForm;

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import LocalStorageService from '../../services/LocalStorageService';
class AdminNavbarLinks extends Component {
  constructor(props) {
    super(props)
    this.localStorageService = new LocalStorageService();

  }
  
  logout() {
    this.localStorageService._clearToken();
    
  }
  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">5</span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );
    // return (
    //   <div>
    //     <Nav>
    //       <NavItem eventKey={1} href="#">
    //         <i className="fa fa-dashboard" />
    //         <p className="hidden-lg hidden-md">Dashboard</p>
    //       </NavItem>
    //       <NavDropdown
    //         eventKey={2}
    //         title={notification}
    //         noCaret
    //         id="basic-nav-dropdown"
    //       >
    //         <MenuItem eventKey={2.1}>Notification 1</MenuItem>
    //         <MenuItem eventKey={2.2}>Notification 2</MenuItem>
    //         <MenuItem eventKey={2.3}>Notification 3</MenuItem>
    //         <MenuItem eventKey={2.4}>Notification 4</MenuItem>
    //         <MenuItem eventKey={2.5}>Another notifications</MenuItem>
    //       </NavDropdown>
    //       <NavItem eventKey={3} href="#">
    //         <i className="fa fa-search" />
    //         <p className="hidden-lg hidden-md">Search</p>
    //       </NavItem>
    //     </Nav>
    //     <Nav pullRight>
    //       <NavItem eventKey={1} href="#">
    //         Account
    //       </NavItem>
    //       <NavDropdown
    //         eventKey={2}
    //         title="Dropdown"
    //         id="basic-nav-dropdown-right"
    //       >
    //         <MenuItem eventKey={2.1}>Action</MenuItem>
    //         <MenuItem eventKey={2.2}>Another action</MenuItem>
    //         <MenuItem eventKey={2.3}>Something</MenuItem>
    //         <MenuItem eventKey={2.4}>Another action</MenuItem>
    //         <MenuItem eventKey={2.5}>Something</MenuItem>
    //         <MenuItem divider />
    //         <MenuItem eventKey={2.5}>Separated link</MenuItem>
    //       </NavDropdown>
    //       <NavItem eventKey={3} href="#">
    //         Log out
    //       </NavItem>
    //     </Nav>
    //   </div>
    // );

    return (
      <div>
        <Nav>
          <NavItem eventKey={1} href="#">
            <i className="fa fa-dashboard" />
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>
        </Nav>
        <Nav pullRight>
          <NavItem eventKey={3} href="/" onClick={() => this.logout()}>
            Log out
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;

import  axios  from 'axios';
import Interceptor from './Interceptor';
class SiteSettingsService {
	constructor() {
		var URL = '';
	}
	
	async getSettings() {
		var settings  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/siteSettings/')
		console.log('settings from service--->>>>', settings)
		return settings.data.body.siteSettings;
	}

	async getSetting(id) {
		var setting  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/siteSettings/' + id)
		return setting.data.body.project[0];
	}

	async addSettings(data) {
		console.log('===>from service',data);
		var settings  = await axios.post(process.env.REACT_APP_BACKEND_URL+'api/v1/siteSettings/', data)
		return settings.data;
	}
	
	async updateSettings(id, data) {
		var settings  = await axios.put(process.env.REACT_APP_BACKEND_URL+'api/v1/siteSettings/' + id, data)
		return settings.data.body;
	}

	// async deleteProject(id, data) {
	// 	var project  = await axios.delete(process.env.REACT_APP_BACKEND_URL+'api/v1/siteSettings/' + id)
	// 	return project.data.body.response;
	// }
}
export default SiteSettingsService;
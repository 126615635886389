/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import UserService from '../../services/UserService';
import LocalStorageService from '../../services/LocalStorageService';
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from "react-router-dom";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      email: '',
      password: '',
      alertMessage: ''
    };
    this.userService = new UserService();
    this.localStorageService = new LocalStorageService();
  }
  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  handleChange = (e) => {
    // console.log(e);
    this.setState({

      ...this.state,
      [e.target.name]: e.target.value

    })
    // console.log(this.state)
  }
  async handleButtonClick(e) {
    // console.log(this.state)
    var user = await this.userService.login({email:this.state.email, password: this.state.password})
    console.log('===>user',user)
    if(user.status.code === 404){
      this.setState({
        ...this.state,
        alertMessage: user.status.message
      })
    } else {
      this.localStorageService._setToken({access_token:user.token, refresh_token: user.token})
      this.props.history.push('/admin/dashboard');
      
    }
    
  }
  render() {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                <div>
                  <div>
                    {this.state.alertMessage}
                  </div>
                  <div>
                    <FormGroup>
                      <ControlLabel>Email address</ControlLabel>
                      <FormControl placeholder="Enter email" type="email" name="email" onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl placeholder="Password" type="password" name="password" autoComplete="off" onChange={this.handleChange} />
                    </FormGroup>

                  </div>
                </div>
                }
                legend={
                  <Button bsStyle="info" fill wd onClick={(e) => this.handleButtonClick(e)}>
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
	Grid,
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl
} from "react-bootstrap";
import UserService from '../../services/UserService.js';
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import avatar from "assets/img/faces/face-3.jpg";

class UserForm extends Component {
	notify = (message) => {toast.success(message)};
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			user: {
				name: '',
				email: '',
				username: '',
				password: ''
			}
		};
		this.userService = new UserService();
		this.routeParam = props.match.params.id;
	}
	componentDidMount() {
		if (this.routeParam) {
			this.fetchUser();
		} else {
			this.setState({
				user: {
					name: '',
					email: '',
					username: '',
					password: ''
				}
			});
		}
	}

	handleChange = (e) => {
		console.log(e);
		this.setState({
			user: {
				...this.state.user,
				[e.target.name]: e.target.value
			}
		})
		console.log(this.state.user)
	}

	async handleButtonClick(e) {
		e.preventDefault();
		console.log(this.state.user);
		if (this.routeParam) {
			var buttonClickResponse = await this.userService.updateUser(this.routeParam, this.state.user);
			if (buttonClickResponse.code == 200) {
				var message = buttonClickResponse.message
				this.notify(message);
			}
			console.log(buttonClickResponse);
		} else {
			var buttonClickResponse = await this.userService.addUser(this.state.user);
			if(buttonClickResponse.status.code == 200) {
				var message = 'User added successfully'
				this.notify(message);
			}
			// console.log(buttonClickResponse);
		}
	}

	async fetchUser() {
		console.log(this.routeParam);
		var user = await this.userService.getUser(this.routeParam);
		this.setState({
			user: {
				name: user.name,
				email: user.email,
				username: user.username,
				password: user.password
			}
		});
		console.log('state-->>', this.state);
	}
	render() {
		return (
			<div className="content">
				<ToastContainer />
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								title="Edit Profile"
								content={
									<form>
										<FormInputs
											ncols={["col-md-5", "col-md-3", "col-md-4"]}
											properties={[
												{
													label: "Company (disabled)",
													type: "text",
													bsClass: "form-control",
													placeholder: "Company",
													defaultValue: "ID Digital",
													disabled: true,
												},
												{
													label: "Username",
													type: "text",
													bsClass: "form-control",
													placeholder: "Username",
													defaultValue: this.state.user ? this.state.user.username : '',
													required: 'required',
													name: 'username',
													onChange: this.handleChange
												},
												{
													label: "Email address",
													type: "email",
													bsClass: "form-control",
													placeholder: "Email",
													defaultValue: this.state.user ? this.state.user.email : '',
													required: 'required',
													name: 'email',
													onChange: this.handleChange
												}
											]}
										/>
										<FormInputs
											ncols={["col-md-6", "col-md-6"]}
											properties={[
												{
													label: "Name",
													type: "text",
													bsClass: "form-control",
													placeholder: "Name",
													defaultValue: this.state.user ? this.state.user.name : '',
													required: 'required',
													name: 'name',
													onChange: this.handleChange
												},
												{
													label: "Password",
													type: "password",
													bsClass: "form-control",
													placeholder: "Password",
													defaultValue: this.state.user ? this.state.user.password : '',
													required: 'required',
													name: 'password',
													onChange: this.handleChange
												}
											]}
										/>



										<Button bsStyle="info" pullRight fill onClick={(e) => this.handleButtonClick(e)}>
											{this.routeParam ? 'Update Profile' : 'Add User'}
										</Button>
										
										<div className="clearfix" />
									</form>
								}
							/>
						</Col>

					</Row>
				</Grid>
			</div>

		);
	}
}

export default UserForm;

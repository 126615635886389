import  axios  from 'axios';
import Interceptor from './Interceptor';
class ProjectsService {
	constructor() {
		var URL = '';
	}
	
	async getProjects() {
		var project  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/portfolio/')
		console.log('project from service--->>>>', project)
		return project.data.body;
	}

	async getProject(id) {
		var project  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/portfolio/' + id)
		return project.data.body.project[0];
	}

	async addProject(data) {
		console.log('===>from service',data);
		var project  = await axios.post(process.env.REACT_APP_BACKEND_URL+'api/v1/portfolio/', data)
		return project.data;
	}
	
	async updateProject(id, data) {
		var project  = await axios.put(process.env.REACT_APP_BACKEND_URL+'api/v1/portfolio/' + id, data)
		return project.data.body;
	}

	async deleteProject(id, data) {
		var project  = await axios.delete(process.env.REACT_APP_BACKEND_URL+'api/v1/portfolio/' + id)
		return project.data.body.response;
	}
}
export default ProjectsService;
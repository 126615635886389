import axios from "axios";
import LocalStorageService from "./LocalStorageService";
// import router from "./router/router";

// LocalstorageService
// const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axios.interceptors.request.use(
	config => {
		// const token = localStorageService.getAccessToken();
		// if (token) {
			console.log('in token');
			config.headers['authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGMyNmIyMmYyZjVkMTdhNDQ3MTRmYTMiLCJlbWFpbCI6ImhhcmlzMUBpZGRpZ2l0YWwuY29tIiwibW9kZWwiOiJVc2VycyIsImlhdCI6MTU3MzAyMjUyNX0.BUxm5kwlzoMQRcXaILdtnY10AkihIr5SewvmBz21CpA';
			console.log(config)
			// }
		// config.headers['Content-Type'] = 'application/json';
		return config;
	},
	error => {
		Promise.reject(error)
	}
	);
import  axios  from 'axios';
import Interceptor from './Interceptor';
class JourneySectionsService {
	constructor() {
		var URL = '';
	}
	
	async getSections() {
		var section  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/journeySections/')
		return section.data.body.sections;
	}

	async getSection(id) {
		var section  = await axios.get(process.env.REACT_APP_BACKEND_URL+'api/v1/journeySections/' + id)
		console.log('==>>section here-->>', section)

		return section.data.body.section;
	}

	async addSection(data) {
		console.log('===>from service',data);
		var section  = await axios.post(process.env.REACT_APP_BACKEND_URL+'api/v1/journeySections/', data)
		return section.data;
	}
	
	async updateSection(id, data) {
		var section  = await axios.put(process.env.REACT_APP_BACKEND_URL+'api/v1/journeySections/' + id, data)
		console.log(section.data)
		return section.data.body;
	}

	async deleteSection(id) {
		var section  = await axios.delete(process.env.REACT_APP_BACKEND_URL+'api/v1/journeySections/' + id)
		console.log(section)
		return section.data.body.response;
	}
}
export default JourneySectionsService;
/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useState } from 'react';
import {
	Grid,
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
} from "react-bootstrap";
import JourneySectionsService from '../../services/JourneySectionsService.js';
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import avatar from "assets/img/faces/face-3.jpg";
import Dropzone from 'react-dropzone';
import upload from 'superagent';
import { any } from "prop-types";
import axios from 'axios';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader'
import Select from "react-select";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class JourneySectionsForm extends Component {
	notify = (message) => { toast.success(message) };

	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			section: {
				name: '',
				contentType: '',
				buttonImage: '',
				buttonText: '',
				parentSection: [],
				sectionContent: '',
				question: ''
			},
			sectionContent: {
				title: '',
				description: '',
				images: {
					image1: {
						title: '',
						url: '',
						excerpt: ''
					},
					image2: {
						title: '',
						url: '',
						excerpt: ''
					}
				},
				videoLink: '',
				col1: '',
				col2: ''
			},
			imagepath: '',
			fields: [],
			imageFields: [{ title: '', url: '', excerpt: '' }],
			allSections: [],
			showButton: true,
			parentIds: [],
			selectedParent: [],
			flag: 1,

		};
		this.JourneySectionsService = new JourneySectionsService();
		this.routeParam = props.match.params.id;
		this.imagepath = '';
		this.contentType = '';
		this.selectedValue = ''
		this.label = ''
		this.selectedParent = [];
		this.parentIds = [];
		// this.selectedParentLabel = '';

	}
	componentWillMount() {
		this.getSections();
		if (this.routeParam) {
			this.fetchSection();
		} else {
			this.setState({
				section: {
					name: '',
					contentType: '',
					buttonImage: '',
					buttonText: '',
					parentSection: [],
					sectionContent: '',
					question: ''
				},
				sectionContent: {
					title: '',
					description: '',
					images: {
						image1: {
							title: '',
							url: '',
							excerpt: ''
						},
						image2: {
							title: '',
							url: '',
							excerpt: ''
						}
					},
					videoLink: '',
					videoTitle: '',
					videoExcerpt: '',
					col1: '',
					col2: ''
				},
				fields: [],
				imageFields: [{ title: '', url: '', excerpt: '' }],
				showButton: true,
				parentIds: [],
				selectedParent: [],
				flag: 0,
			});
		}

	}

	componentDidMount() {


	}

	onDropButtonImage(file) {
		console.log('-->>this is file', file)
		upload.post(process.env.REACT_APP_BACKEND_URL + 'api/v1/portfolio/upload')
			.attach('theseNamesMustMatch', file[0])
			.end((err, res) => {
				console.log('==>>urll->>>', res.body.body.url)
				this.setState({
					...this.state,
					section: {
						...this.state.section,
						buttonImage: res.body.body.url
					}
				})
				// this.state.sectionContent.images.image1.url = res.body.body.url
			})
	}
	onDrop(file, id) {
		console.log('-->>this is file', file)
		upload.post(process.env.REACT_APP_BACKEND_URL + 'api/v1/portfolio/upload')
			.attach('theseNamesMustMatch', file[0])
			.end((err, res) => {
				console.log('==>>urll->>>', res.body.body.url)
				this.state.imageFields[id].url = res.body.body.url
				this.setState({
					...this.state
				})

			})
	}
	onDropVideo(file) {
		console.log('-->>this is file', file)
		upload.post(process.env.REACT_APP_BACKEND_URL + 'api/v1/portfolio/upload')
			.attach('theseNamesMustMatch', file[0])
			.end((err, res) => {
				console.log('==>>urll->>>', res.body.body.url)
				this.setState({
					sectionContent: {
						...this.state.sectionContent,
						videoLink: res.body.body.url
					}
				})
				// this.state.sectionContent.images.image2.url = res.body.body.url
				console.log('=>>>content url', this.state.sectionContent.images.image2.url)
			})
	}

	handleChange = (e) => {
		console.log(e.target.value);
		this.setState({
			section: {
				...this.state.section,
				[e.target.name]: e.target.value

			}
		})
		console.log(this.state.section)
	}

	handleChangeSectionContent = (e) => {
		console.log(e);
		this.setState({
			...this.state,
			sectionContent: {
				...this.state.sectionContent,
				[e.target.name]: e.target.value
			}
		})
		console.log(this.state.sectionContent)
	}

	handleDescriptionChange = (e) => {
		console.log(e);
		this.setState({
			...this.state,
			sectionContent: {
				...this.state.sectionContent,
				description: e
			}
		})
		console.log(this.state.sectionContent)
	}
	handleCol1Change = (e) => {
		console.log(e);
		this.setState({
			...this.state,
			sectionContent: {
				...this.state.sectionContent,
				col1: e
			}
		})
		console.log(this.state.sectionContent)
	}

	handleCol2Change = (e) => {
		console.log(e);
		this.setState({
			...this.state,
			sectionContent: {
				...this.state.sectionContent,
				col2: e
			}
		})
		console.log(this.state.sectionContent)
	}

	handleContentTypeSelectionChange = (e) => {
		console.log(e);
		this.setState({
			section: {
				...this.state.section,
				contentType: e.value
			}
		})
		this.contentType = e.value;
		console.log('updated--->>>', this.state.section.contentType)
		this.showHideFields();
	}

	async getSections() {
		var sections = await this.JourneySectionsService.getSections();
		console.log('====>>sections--->>', sections)
		this.setState({
			...this.state,
			allSections: sections
		})
	}
	async handleButtonClick(e) {
		e.preventDefault();
		this.state.sectionContent.images = this.state.imageFields;
		this.state.section.parentSection = this.parentIds
		console.log(this.state);
		if (this.routeParam) {
			console.log(this.parentIds)
			var buttonClickResponse = await this.JourneySectionsService.updateSection(this.routeParam, this.state);
			console.log('resp==>>>', buttonClickResponse)
			if (buttonClickResponse.response.code == 200) {
				var message = 'Section updated successfully'
				this.notify(message);
			}
			console.log(buttonClickResponse);
		} else {
			var buttonClickResponse = await this.JourneySectionsService.addSection(this.state);
			if (buttonClickResponse.status.code == 200) {
				var message = 'Section added successfully'
				this.notify(message);
			}
			// console.log(buttonClickResponse);
		}
	}

	async fetchSection() {
		console.log('==>oarammm', this.routeParam);
		var section = await this.JourneySectionsService.getSection(this.routeParam);
		if (section.sectionContent.images) {
			this.state.imageFields = section.sectionContent.images
		}
		console.log('fetched section--->>>', section)
		console.log('imagesfirld->', this.imageFields)
		this.setState({
			section: {
				name: section.name,
				contentType: section.contentType,
				buttonImage: section.buttonImage,
				buttonText: section.buttonText,
				parentSection: section.parentSection,
				question: section.question
			},
			sectionContent: section.sectionContent
		});


		this.contentType = section.contentType

		this.showHideFields();

		console.log('contentType--->>>>', this.selectedValue)
		this.setState({
			...this.state,
			showButton: true
		})
	}


	showHideFields() {
		this.state.fields = [];
		if (this.contentType === 'text' || this.contentType === 'conversionResponse' || this.contentType === 'conversionForm') {
			this.state.fields.push('title', 'description')
		} else if (this.contentType === 'video') {
			this.state.fields.push('title', 'description', 'videoLink')
		} else if (this.contentType === 'imageGallery') {
			this.state.fields.push('title', 'description', 'images')
		} else if (this.contentType === 'videoImage') {
			this.state.fields.push('videoLink', 'description', 'images')
		} else if (this.contentType === 'twoCols') {
			this.state.fields.push('title', 'description', 'columns')
		}

		var result = this.contentType.replace(/([A-Z])/g, " $1");
		var label = result.charAt(0).toUpperCase() + result.slice(1);
		this.selectedValue = this.contentType;
		this.label = label
		this.state.section.parentSection.map((value, id) => {
			console.log('vals---', value)
			if (Array.isArray(value)) {
				this.state.selectedParent.push({ value: value[0]._id, label: value[0].name })
				this.parentIds.push(value[0]._id)
			} else {
				this.state.selectedParent.push({ value: value._id, label: value.name })
				this.parentIds.push(value._id)
			}
		})
		this.state.section.parentSection = this.state.selectedParent
		console.log('Selected parent -->>>????', this.state.selectedParent)



		console.log(this.state.fields)
		// this.setState({
		// 	...this.state,
		// 	sectionContent: {
		// 		...this.state.sectionContent,
		// 	},
		// 	section: {
		// 		...this.state.section
		// 	},
		// 	showButton: true
		// })
	}

	handleImageTitleChange = (e, id) => {
		console.log(this.state.fields)
		this.state.imageFields[id].title = e.target.value
		console.log(this.state.imageFields[id])

	}

	handleImageExcerptChange = (e, id) => {
		this.state.imageFields[id].excerpt = e.target.value

	}

	addFields() {
		this.setState({ imageFields: [...this.state.imageFields, { title: '', url: '', excerpt: '' }] })
	}
	removeButton = (e) => {
		console.log('buttonRemoved===')
		this.state.section.buttonImage = '';
		this.state.section.buttonText = '';
		this.setState({
			...this.state,
			showButton: false
		})
		this.showButton = false;
	}

	render() {
		const uploadOptions = {
			server: 'http://3.106.14.188:4000',
			signingUrlQueryParams: { uploadType: 'avatar' },
		}
		const s3Url = 'https://new-idsite-2019.s3-ap-southeast-2.amazonaws.com';
		var titleField;
		var descriptionField;
		var videoField;
		var videoTitle;
		var videoExcerpt;
		var imagesBlock;
		var columnsField

		if (this.state.fields.includes('title')) {
			titleField = <FormInputs
				ncols={["col-md-12"]}
				properties={[
					{
						label: "Title",
						type: "text",
						bsClass: "form-control",
						placeholder: "Title",
						name: 'title',
						required: 'required',
						defaultValue: this.state.sectionContent ? this.state.sectionContent.title : '',
						onChange: this.handleChangeSectionContent
					}
				]}
			/>
		} if (this.state.fields.includes('videoLink')) {
			videoField = <div><FormInputs
				ncols={["col-md-12"]}
				properties={[
					{
						label: "Video",
						type: "text",
						bsClass: "form-control",
						placeholder: "http://youtube.com",
						name: 'videoLink',
						required: 'required',
						defaultValue: this.state.sectionContent ? this.state.sectionContent.videoLink : '',
						onChange: this.handleChangeSectionContent
					}
				]}
			/>
				<div>
					<Dropzone onDrop={(e) => this.onDropVideo(e)} multiple={false}>
						{({ getRootProps, getInputProps }) => (
							<div className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

						)}
					</Dropzone>
					{/* {this.state.sectionContent.videoLink !== '' ? <img src={this.state.sectionContent.videoLink} style={{ width: 200 + 'px', height: 200 + 'px' }} /> : ''} */}
					{this.state.sectionContent.videoLink !== '' ? <video style={{ 'max-width': 25 + '%' }} >
						<source src={this.state.sectionContent.videoLink} type="video/mp4" />
						<source src={this.state.sectionContent.videoLink} type="video/ogg" />
						Your browser does not support the video tag.
																	</video> : ''}
				</div>
			</div>
		} if (this.state.fields.includes('videoLink')) {
			videoTitle = <FormInputs
				ncols={["col-md-12"]}
				properties={[
					{
						label: "Video Title",
						type: "text",
						bsClass: "form-control",
						placeholder: "Video 1",
						name: 'videoTitle',
						required: 'required',
						defaultValue: this.state.sectionContent ? this.state.sectionContent.videoTitle : '',
						onChange: this.handleChangeSectionContent
					}
				]}
			/>
		} if (this.state.fields.includes('videoLink')) {
			videoExcerpt = <FormInputs
				ncols={["col-md-12"]}
				properties={[
					{
						label: "Video Excerpt",
						type: "text",
						bsClass: "form-control",
						placeholder: "This is the Video",
						name: 'videoExcerpt',
						required: 'required',
						defaultValue: this.state.sectionContent ? this.state.sectionContent.videoExcerpt : '',
						onChange: this.handleChangeSectionContent
					}
				]}
			/>
		} if (this.state.fields.includes('images')) {
			imagesBlock = <div>
				{this.state.imageFields.map((field, id) => {
					return (
						<div key={`${field}-${id}`}>
							<p className="category">Images</p>
							<label htmlFor="">Image {`${id + 1}`} {id % 2 == 0 ? ' - Landscape Image' : ' - Portrait Image'}</label>
							<div>
								<Dropzone onDrop={(e) => this.onDrop(e, id)} multiple={false}>
									{({ getRootProps, getInputProps }) => (
										<div id="dropzone" className="dropzone" className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

									)}
								</Dropzone>
								{this.state.imageFields[id].url ? <img src={this.state.imageFields[id].url} style={{ 'max-width': 25 + '%' }} /> : ''}
							</div>
							<FormInputs
								ncols={["col-md-12"]}
								properties={[
									{
										label: "Image 1 Title",
										type: "text",
										bsClass: "form-control",
										placeholder: "Title",
										name: 'image1Title',
										required: 'required',
										defaultValue: this.state.imageFields[id] ? this.state.imageFields[id].title : '',
										onChange: (e) => this.handleImageTitleChange(e, id)
									}
								]}
							/>
							<FormInputs
								ncols={["col-md-12"]}
								properties={[
									{
										label: "Image 1 Excerpt",
										type: "text",
										bsClass: "form-control",
										placeholder: "Excerpt",
										name: 'image1Excerpt',
										required: 'required',
										defaultValue: this.state.imageFields[id] ? this.state.imageFields[id].excerpt : '',
										onChange: (e) => this.handleImageExcerptChange(e, id)
									}
								]}
							/>
							<Button bsStyle="info" type="button" onClick={() => this.addFields()}>Add More</Button>
						</div>)
				})}

			</div>
		} if (this.state.fields.includes('description')) {
			descriptionField = <FormGroup controlId="formControlsTextarea">
				<ControlLabel>Description</ControlLabel>
				{/* <FormControl
					rows="5"
					componentClass="textarea"
					bsClass="form-control"
					placeholder="Description of the project"
					name='description'
					onChange={this.handleChangeSectionContent}
					defaultValue={this.state.sectionContent ? this.state.sectionContent.description : ''}
				/> */}
				<CKEditor
					editor={ClassicEditor}
					data={this.state.sectionContent ? this.state.sectionContent.description : ''}
					name='description'
					onInit={editor => {
						// You can store the "editor" and use when it is needed.
						console.log('Editor is ready to use!', editor);
					}}
					onChange={(event, editor) => this.handleDescriptionChange(editor.getData())}

				/>
			</FormGroup>
		} if (this.state.fields.includes('columns')) {
			columnsField = <FormGroup controlId="formControlsTextarea">
				<ControlLabel>Column 1</ControlLabel>
				{/* <FormControl
					rows="5"
					componentClass="textarea"
					bsClass="form-control"
					placeholder="Description of the project"
					name='description'
					onChange={this.handleChangeSectionContent}
					defaultValue={this.state.sectionContent ? this.state.sectionContent.description : ''}
				/> */}
				<CKEditor
					editor={ClassicEditor}
					data={this.state.sectionContent ? this.state.sectionContent.col1 : ''}
					name='description'
					onInit={editor => {
						// You can store the "editor" and use when it is needed.
						console.log('Editor is ready to use!', editor);
					}}
					onChange={(event, editor) => this.handleCol1Change(editor.getData())}

				/>
				<ControlLabel>Column 2</ControlLabel>
				{/* <FormControl
					rows="5"
					componentClass="textarea"
					bsClass="form-control"
					placeholder="Description of the project"
					name='description'
					onChange={this.handleChangeSectionContent}
					defaultValue={this.state.sectionContent ? this.state.sectionContent.description : ''}
				/> */}
				<CKEditor
					editor={ClassicEditor}
					data={this.state.sectionContent ? this.state.sectionContent.col2 : ''}
					name='description'
					onInit={editor => {
						// You can store the "editor" and use when it is needed.
						console.log('Editor is ready to use!', editor);
					}}
					onChange={(event, editor) => this.handleCol2Change(editor.getData())}

				/>
			</FormGroup>
		}

		return (
			<div className="content">
				<ToastContainer />
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								title="Add/Edit Sections"
								content={
									<form>

										<FormInputs
											ncols={["col-md-12"]}
											properties={[
												{
													label: "Name",
													type: "text",
													bsClass: "form-control",
													placeholder: "Name",
													name: 'name',
													required: 'required',
													defaultValue: this.state.section ? this.state.section.name : '',
													onChange: this.handleChange
												}
											]}
										/>

										<Col md={12}>
											<p className="category" >SELECT CONTENT TYPE</p>
											<Select
												className="react-select primary"
												classNamePrefix="react-select"
												name='contentType'
												value={{ value: this.selectedValue, label: this.label }}
												// value={this.selectedValue}
												// {this.state.section.contentType === 'video' ? { value: "video", label: "Video" } 
												// : this.state.section.contentType === 'text' ? { value: "text", label: "Text" } 
												// : this.state.section.contentType === 'imageGallery' ? { value: "imageGallery", label: "Image Gallery" }
												// : this.state.section.contentType === 'videoImage' ? { value: "videoImage", label: "Video and Image" }
												// : this.state.section.contentType === 'conversionForm' ? { value: "conversionForm", label: "Conversion Form" }
												// : this.state.section.contentType === 'conversionResponse' ? { value: "conversionResponse", label: "Conversion Response" }
												// : { value: "text", label: "Text" } 

												// }
												onChange={this.handleContentTypeSelectionChange}
												options={[
													{ value: "text", label: "Text", selected: true },
													{ value: "video", label: "Video" },
													{ value: "imageGallery", label: "Image Gallery" },
													{ value: "videoImage", label: "Video and Image" },
													{ value: "conversionForm", label: "Conversion Form" },
													{ value: "conversionResponse", label: "Conversion Response" },
													{ value: "twoCols", label: "Two Columns" },

												]}
												placeholder="Select Content Type"
											/>
										</Col>

										{titleField}
										{videoField}
										{videoTitle}
										{videoExcerpt}
										{imagesBlock}
										{descriptionField}
										{columnsField}

										<div class="divider div-transparent div-arrow-down"></div>
										<Col md={12}>

											<FormInputs
												ncols={["col-md-12"]}
												properties={[
													{
														label: "Question",
														type: "text",
														bsClass: "form-control",
														placeholder: "Question",
														name: 'question',
														required: 'required',
														defaultValue: this.state.section ? this.state.section.question : '',
														onChange: this.handleChange
													}
												]}
											/>
										</Col>
										{
											this.state.showButton === true ? <Row>
												<Col md={12}>
													<h5>Button</h5>
													<label htmlFor="">Button Image</label>
													<div>
														<Dropzone onDrop={(e) => this.onDropButtonImage(e)} multiple={false}>
															{({ getRootProps, getInputProps }) => (
																<div className="dropzone" {...getRootProps()}> <input {...getInputProps()} />Click here to upoad a new file</div>

															)}
														</Dropzone>
														{this.state.section.buttonImage !== '' ? <img src={this.state.section.buttonImage} style={{ 'max-width': 25 + '%' }} /> : ''}
													</div>
													<FormInputs
														ncols={["col-md-12"]}
														properties={[
															{
																label: "Button Text",
																type: "text",
																bsClass: "form-control",
																placeholder: "Button Text",
																name: 'buttonText',
																required: 'required',
																defaultValue: this.state.section ? this.state.section.buttonText : '',
																onChange: this.handleChange
															}
														]}
													/>
												</Col>
												<Button bsStyle="info" pullRight onClick={(e) => this.removeButton(e)}> Remove Button</Button>
											</Row> : ''
										}




										<Col md={12}>
											<p className="category">SELECT PARENT SECTIONS</p>
											<Select
												className="react-select info"
												classNamePrefix="react-select"
												placeholder="Choose Parent Section"
												name="parentSection"
												closeMenuOnSelect={true}
												isMulti
												defaultValue={this.state.selectedParent}
												onChange={(val) => {

													let difference = this.state.selectedParent.filter(x => !val.includes(x)); // calculates diff
													console.log('Removed: ', difference);                         // prints array of removed
													console.log('values',val.value, val)
													this.state.selectedParent = val
													this.parentIds = [];
													val.map((v) => {
														
														this.parentIds.push(v.value)
														console.log(this.parentIds);
														// console.log('v.value--',v.value)
													})
													// this.setState({
													// 	...this.state,
													// 	selectedParent:  [...val]
													// });
													// if (val.length != 0) {
													// 	val.map((val) => {
													// 		// console.log(val)
													// 		// this.setState({
													// 		// 	...this.state,
													// 		// 	section: {
													// 		// 		...this.state.section,
													// 		// 		parentSection: [value.value]
													// 		// 	}
													// 		// })
													// 		if (!this.state.parentIds.includes(val.value)) {
													// 			// this.state.parentIds.push(val.value)
													// 			// this.state.selectedParent.push(val)
													// 			var previousParents = this.state.parentIds;
													// 			var previousSelected = this.state.selectedParent
													// 			console.log('selecttssss', previousParents, previousSelected, val)
													// 			this.setState({
													// 				...this.state,
													// 				parentIds: [...this.state.parentIds, val.value],
													// 				selectedParent: [...this.state.selectedParent, val]
													// 			})
													// 		} else {
													// 			console.log(val)
													// 			this.setState({
													// 				...this.state,
													// 				parentIds: [...this.state.parentIds, val.value],
													// 				selectedParent: [...this.state.selectedParent, val]
													// 			})
													// 			// this.state.selectedParent.filter(x => !val.includes(x))
													// 			console.log('removed', this.state.selectedParent);
													// 		}

															console.log(this.state)
													// 	})
													// }

												}


												}
												options={this.state.allSections.map((listValue, index) => {
													return ({ value: listValue._id, label: listValue.name })
												})}
											/>
										</Col>

										<Button bsStyle="info" pullRight fill onClick={(e) => this.handleButtonClick(e)}>
											{this.routeParam ? 'Update Section' : 'Add Section'}
										</Button>

										<div className="clearfix" />
									</form>
								}
							/>
						</Col>

					</Row>
				</Grid>
			</div>

		);
	}
}

export default JourneySectionsForm;
